.simple{
    font-size: 17px;
     font-family: 'Inter', sans-serif;
         height: 100px;
}

.fivedo{
         font-size: 35px;
    color: #000 !important;
    font-weight: 700;
    height: 70px;
}

.usdbol{
        font-weight: 700;
    font-size: 11px;
    color: #000 !important;
    padding-top: 0;
        height: 40px;
}
.simplee{
        font-size: 14px;
    color: #727272 !important;
        margin-bottom: -18px;
            height: 50px;
}
.includes{
         font-size: 20px;
    color: #434343 !important;
    font-weight: 400;
    height: 70px;
}

.dot-cobte{
        text-align: center;
    padding: 20px;
    background:transparent !important;
}

.tesstiomsecsss{
        width: 408px;
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.bordre{    width: 50%;}

.nummm{
        display: flex;
    /* width: 10%; */
    align-items: center;
}

.border-sec{}

.mymysl{
   padding: 0 !important;
   margin-top: 40px;

}

.slideshow-wework{
        background: transparent !important; 
}

.form-section{
      padding: 50px 0;
    background-color: #f9f2fd;
}


.form-sec{
    text-align: center;
}

.form-sec h2{

    font-family: 'Inter', sans-serif;
    font-weight: 600;
    font-size: 27px;

}

.emass{
       text-align: left;
    color: #000;
    font-size: 12px;
    margin-bottom: 0;
    font-weight: 500;
}

input:focus ~ label, textarea:focus ~ label, input:valid ~ label, textarea:valid ~ label {
    font-size: 0.75em;
    color: #999;
    top: -5px;
    -webkit-transition: all 0.225s ease;
    transition: all 0.225s ease;
}

.styled-input {
    float: left;
    width: 293px;
    margin: 1rem 0;
    position: relative;
    border-radius: 4px;
}

@media only screen and (max-width: 768px){
    .styled-input {
        width:100%;
    }
}

.styled-input label {
   color: #999;
    padding: 0.3rem 00 0rem 18px;
    position: absolute;
    top: 10px;
    left: 0;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    pointer-events: none;
    font-size: 10px;
}

.styled-input.wide { 
    width: 631px;
    max-width: 100%;
}

input,
textarea {
 padding: 10px;
    border: 0;
    width: 100%;
    font-size: 10px;
    background-color: #2d2d2d00;
    color: black;
    border: 2px solid #b7b7b7;
}

input:focus,
textarea:focus { outline: 0; }

input:focus ~ span,
textarea:focus ~ span {
    width: 100%;
    -webkit-transition: all 0.075s ease;
    transition: all 0.075s ease;
}

textarea {
    width: 100%;
    min-height: 15em;
}

.input-container {
    width: 670px;
    max-width: 100%;
    margin: 40px auto 25px auto;
}

.submit-btn {
     /* float: right; */
    padding: 11px 52px;
    /* border-radius: 60px; */
    display: inline-block;
    background-color: #197A4A;
    color: white;
    font-size: 20px;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 6%), 0 2px 10px 0 rgb(0 0 0 / 7%);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
}

.submit-btn:hover {
    transform: translateY(1px);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,0.10),
              0 1px 1px 0 rgba(0,0,0,0.09);
}

@media (max-width: 768px) {
    .submit-btn {
        width:100%;
        float: none;
        text-align:center;
    }
}

input[type=checkbox] + label {
  color: #ccc;
  font-style: italic;
} 

input[type=checkbox]:checked + label {
  color: #f00;
  font-style: normal;
}


.everystep-section{
        padding: 50px 0;
    background-color: #f9f2fd;
}

.easycase{
    margin-bottom: 50px;
}

.every-ste1{}

.numco{
       background-color: #9226dc5c;
    width: 10%;
    border-radius: 60px;
    width: 27%;
    height: 97px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 46px;
  
   
}



#margb{
    margin-bottom:70px ;
}
.every-ste1 h2{
       background-color: #9226dc;
    color: #fff;
    width: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 20px 20px; */
    border-radius: 60px;
    width: 70px;
    height: 70px;
     font-family: 'Inter', sans-serif;
     font-size: 20px;
         margin-bottom: 0;
}

.every-ste1 h3{
        font-family: 'Inter', sans-serif;
    font-size: 28px;
    padding: 5px 0;
    font-weight: 400;
}

.every-ste1 p{
        font-family: 'Inter', sans-serif;
    font-size: 16px;
    line-height: 26px;
    font-weight: 400;
}


.main-carous-se{
        padding: 50px 0;
    background-color: #f9f2fd;
}
.easyas{
    text-align: center;
}

.easyas h3{
       color: #9226dc;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 500;

}

.easyas h2{
       font-family: 'Inter', sans-serif;
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;

}

#caros-se-work{
        margin-top: 40px;
}

.blog .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -40px;

}

/* The colour of the indicators */
.blog .carousel-indicators li {
    background: #a3a3a3;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    font-family: 'Inter', sans-serif;

}

.blog .carousel-indicators .active {
background: #707070;
    margin-right: 8px;
}


.work-secs{
        padding-top: 50px;
}

.work-img{}



.ourplan-table-section{
    padding: 50px 0;
}


.featrr{
    text-align: left;
        width: 40%;
            font-weight: 700;
    font-size: 13px;
}


.table-plan{
    text-align: center;
}

.table-plan h2{
    font-family: 'Inter', sans-serif;

}

table { 
       width: 100%;
    border-collapse: collapse;
    margin: 50px auto;
    border: 1px solid #b9b9b9;
    border-bottom: 2px solid #bcbcbc;
    }

/* Zebra striping */
tr:nth-of-type(odd) { 
    background: #eee; 
    }

th { 
    background: #525252; 
    color: white; 
    font-weight: bold; 
    }

td, th { 
       padding: 10px;
    border: none;
    text-align: center;
    font-size: 16px;
    }


    #gpst{
            color: #7f38c5;
    }

.chnfo{
    font-size: 20px !important;
}

.price2{
    background-color: white !important;
}

.price2 .header{
        background-color: #ededed;
}

.ourplans-section-plans{
        padding: 50px 0;
    background-color: #ededed;
}

.ourplans-inner-sectionplan{
     background-color: #ededed;
         padding-bottom: 40px;
}


.test-main{
        box-shadow: 0 0 5px 0px #d2d2d2;
    padding: 30px;
    margin: 10px;
}

.test-test-imgsec{
    display: flex;
        justify-content: space-between;
}

.test-test-img1{}


.test-test-img2{
    margin-left: 80px;
}

#icsetest{
    font-size: 60px;
    color: #d7d7d7;
}

.test-test-sec{

}

.test-test-sec h2{
        font-size: 24px;
    font-weight: 500;
      font-family: 'Inter', sans-serif;
      margin-top: 20px;
}


.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: auto;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none;
     }

.test-test-sec p{
         font-family: 'Inter', sans-serif;
    font-size: 13px;
    font-weight: 500;
    line-height: 25px;
    color: #525252;
}

#owlcar{}

.about-us-section{
    padding: 50px 0;
        background-color: #f9f2fd;
}

.about-uss-text{}

.about-uss-text h2{
        font-size: 35px;
    font-weight: 600;
        font-family: 'Inter', sans-serif;
}

.about-uss-text p{
       font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    color: #282828;
}

.about-uss-img{
        margin-top: 50px;
}


#about-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 72%;
}

.testimonial-sec-about{
    padding: 30px 0;
}


.testi-abou {
    text-align: center;
}

.testi-abou h3{
        background-color: #EDFFCF;
    width: 12%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #32895E;
    border-radius: 50px;
    padding: 8px 0;
    font-size: 19px;
    font-weight: 400;
      font-family: 'Inter', sans-serif;
}
div#blogCarousel {
    margin-top: 40px;
}

.testi-abou h2{
          font-size: 28px;
    font-weight: 400;
    font-family: 'Inter', sans-serif;
    line-height: 38px;
    margin-top: 13px;
}



.blog .carousel-indicators {
    left: 0;
    top: auto;
    bottom: -40px;

}

/* The colour of the indicators */
.blog .carousel-indicators li {
    background: #a3a3a3;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    margin-right: 8px;
}

.blog .carousel-indicators .active {
background: #707070;
}

/*aboutus*/




.navbar .navbar-brand {
  font-size: 30px;
}
.navbar .nav-item {
  padding:0;
}
.navbar .nav-link {
    font-size: 17px;
    margin-left: 0;
    font-weight: 500;
    color: #000;
}
a.nav-link {
    color: #000;
}
.nav-link:focus, .nav-link:hover {
    color: #000000;
}

.fa-bars {
  color: #000;
  font-size: 30px;
}


.riyo-header-section{
  position: relative;
}


.riyo-header-section::before{
    background-image: url(../images/header-left.png);
     content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 100px;
      top: 0;
	   
}

.slider-section{
	position: relative;
}
.slider-section::before{
	background-image: url(../images/header-right.png);
     content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      height: 130px;

    background-position: right;

     
}

.riyo-header-img{
	margin-top: 10px;
}




@media (min-width: 576px){
.navbar-expand-sm .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    justify-content: right;

}
}


.navbar-dark .navbar-nav .nav-link {
    color: #000;
        font-style: normal;
    font-weight: 400;
    font-size: 14px;
    font-family: 'Inter', sans-serif;

}

.navbar-dark .navbar-nav .nav-link:hover {
    color: #000;
}

.bg-dark {
    background-color:transparent !important;
}

li.nav-item {
    margin-left: 16px;
}


#home-col{
	    /* color: #197B4A; */
	    color: #000000;
}

#signin-button{
	background-image: linear-gradient(#32895E, #8ABE4B);
    color: #fff;
    border-radius: 6px;
    padding: 5px 26px;
}

.navbar-nav{align-items: center}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: #197b4a;
}

.work-about h2{
    text-align: center;
    font-size: 50px !important;
}

.work-schedule{
	    margin-left: 35px;
    margin-top: 90px;
    width: 100%;
}

.work-schedule h2{
	color: #db3333;
	font-weight: 700;
	font-size: 40px;
	/* font-family: 'Inter', sans-serif; */
    font-family: Georgia, serif;
    /* font-style: italic; */
}

ul.dropdown-menu.show li{
        border-bottom: 1px solid #bdbdbd;
    padding: 10px 10px;
}

ul.dropdown-menu.show li a{
          color: #000;
    text-decoration: none;
    font-size: 14px;
    font-weight: 400;
}


ul.dropdown-menu.show li:hover{
        background-color: #efefef;
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: 0 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.15);
    border-radius: 0.25rem;
}

.work-schedule h3{
	    font-weight: 600;
    font-size: 30px;
    color: #000;
    line-height: 40px;
   font-family: 'Inter', sans-serif;
}

.work-schedule p{
        font-weight: 500;
    font-size: 16px;
    color: #383838;
    line-height: 25px;
    padding-top: 10px;
    font-family: 'Inter', sans-serif;
}


.get-started{
	    background-image: linear-gradient(#32895E, #8ABE4B);
    color: #fff;
    border-radius: 26px;
    padding: 7px 23px;
    border: none;
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
   font-family: 'Inter', sans-serif;
}


#img-width{
	width: 100%;
}


.leanr-how-text{
	    padding-top: 80px;
}

.leanr-how-text h2{
	font-weight: 700;
	font-size: 20px;
	font-family: 'Inter', sans-serif;
}

.leanr-how-text p{
		font-weight: 400;
	font-size: 14px;
	font-family: 'Inter', sans-serif;
}

.leanr-how-text ul{
	    padding-left: 0;
}

.leanr-how-text ul li{
		 font-weight: 500;
    font-size: 14px;
  font-family: 'Inter', sans-serif;
    line-height: 36px;
    color: #000;
    list-style: none;
}

#icon-chan{
	      color: #32895E;
    border-radius: 50px;
    border: 2px solid #32895E;
    font-size: 9px;
    padding: 1px 1px;
        margin-right: 10px;
}
.learn-how-section {
    background-color: #ededed;
}



.features-section{
	position: relative;
	padding: 50px 0;
}
.features-section::before{
	  background-image: url(../images/Vector.png);
     content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      background-repeat: no-repeat;
      background-size: contain;
      width: 100%;
      top: 0;
      background-position: right;
          z-index: -1;
}







.features{
	    padding-top: 50px;
}

.features h3{
	color: #197A4A;
	font-family: 'Inter', sans-serif;
	  font-weight: 500;
	  font-size: 30px;
}

.features h2{
	 font-family: 'Inter', sans-serif;
	    font-weight: 500;
	  font-size: 35px;
	  color: #000;
}

.team-manag-sec{
	    display: flex;
	        padding-top: 40px;
}


#team-man-igf{
	    width: 70%;
}


.team-manag{}

.team-manag-text{}

.team-manag-text h2{
	    font-size: 18px;
    font-weight: 600;
   font-family: 'Inter', sans-serif;
}

.team-manag-text p{
	    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 15px;
    color: #252525;
    line-height: 20px;
}

.teammang-secc{
	    margin-left: 150px;
}

.our-solutin-section{
	    background-color: #ededed;
	        padding: 50px 0;
}


.oursolut {}

.oursolut h2{
    color: #117C4B;
    font-weight: 600;
    font-size: 40px;
    text-align: center;
   font-family: 'Inter', sans-serif;
}

.oursolut-icons{
	      background-image: linear-gradient(#32895E, #8ABE4B);
    border-radius: 100px;
    width: 190px;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
        margin-top: 50px;
}

.oursolut-iconss{
          /* background-image: linear-gradient(#000000, #db3333); */
          /* background-image: linear-gradient(#000000, #db3333); */
    border-radius: 100px;
    width: 170px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}
.oursolut-iconss2{
    /* background-image: linear-gradient(#000000, #db3333); */
    /* background-image: linear-gradient(#000000, #db3333); */
border-radius: 100px;
width: 190px;
height: 190px;
display: flex;
align-items: center;
justify-content: center;
margin-left: auto;
margin-right: auto;
}


#img1{
	width: 75%;
}
#img1new{
	width: 50%;
}

.ousoltext{
    text-align: center;
}

.ousoltext h2{
	    text-align: center;
    font-weight: 600;
    font-size: 25px;
    color: #000;
    padding-top: 20px;
    font-family: 'Inter', sans-serif;
}


.ousoltext1{
    text-align: center;
    font-family: 'Georgia', serif;
}

.ousoltext1 h3{
    padding-top: 20px;
    color: #32895E;
    font-family: 'Georgia', serif;

}

.ousoltext1 h2{
        text-align: center;
    font-weight: 600;
    font-size: 25px;
    color: #000;
    
    font-family: 'Inter', sans-serif;
}


.ourplans-section{
        padding: 50px 0;
}

.ourplans-tex{
    text-align: center;
}

.ourplans-tex h3{
        color: #197A4A;
   font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 30px;
}

.ourplans-tex h2{
    font-family: 'Inter', sans-serif;
    font-weight: 500;
    font-size: 35px;
}

.ourplans-tex p{
     margin-top: 17px;
      font-family: 'Inter', sans-serif;
}

.monthly{
    font-weight: 400;
   
}


.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #32895E;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.columns {
  float: left;
  width: 90%;
  padding: 8px;
}

.price {
    list-style-type: none;
    /* border: 1px solid #eee; */
    margin: 0;
    padding: 0;
    -webkit-transition: 0.3s;
    transition: 0.3s;
    border-radius: 25px;
    background-color: transparent;
        box-shadow: 0 10px 5px 0px rgb(0 0 0 / 15%);
         
}




.price .header {
    /* background-image: url(../images/shap.png); */
    background-color: #000000;
    background-repeat: no-repeat;
    border-radius: 25px 25px 0 0;
    background-size: cover;
    height: 110px;
    justify-content: center;
    color: white;
    font-size: 40px;
    border: none;
    /* padding-top: 10px; */
   font-family: 'Inter', sans-serif;

}

.price .header span{
        margin-left: 15px;
    font-family: 'Inter', sans-serif;
    font-weight: 100;
}




.price li {
 
     padding: 25px;
  text-align: center;
 
          color: #767676;
          font-family: 'Inter', sans-serif;
}

.price .grey {
    font-size: 23px;
    justify-content: center;
    align-items: center;
    color: #838383;
       font-family: 'Inter', sans-serif;
       border: none;
           height: 90px;
    max-height: 90px;
}

.button {
      background-image: linear-gradient( #000000 , #650000);
  border: none;
  color: white;
  padding: 9px 40px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  border-radius: 4px;
     font-family: 'Inter', sans-serif;
}

#icnb{
    color: #32895E ;
}

#icon-re{
    color: #dc2121;
}

.price .grey span{
        font-size: 34px;
    font-weight: bold;
    /* align-items: center; */
    color: #32895E;
       font-family: 'Inter', sans-serif;
}

.trustedsection{
        background-image: url(../images/bc.png);
       padding: 50px 0;
    background-repeat: no-repeat;
    background-size: cover;
}


.trusted-text{}

.trusted-text h2{
        text-align: center;
            text-align: center;
    font-weight: 500;
    font-size: 35px;
       font-family: 'Inter', sans-serif;
}

.trusted-text h2 span{
    color: #32895E;
    font-weight: 700;
}

.imgg{

    margin-top: 30px;

}

/* Slideshow container */
.slideshow-container {
     position: relative;
    background: #fffffff1;
}

/* Slides */
.mySlides {
  display: none;
  padding: 40px;
  text-align: center;
}

/* Next & previous buttons */
.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  margin-top: -30px;
  padding: 16px;
  color: #888;
  font-weight: bold;
  font-size: 20px;
  border-radius: 0 3px 3px 0;
  user-select: none;
      margin: 0 -60px;
}

/* Position the "next button" to the right */
.next {
  position: absolute;
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover, .next:hover {
  background-color: transparent;
  color: #000;
}

/* The dot/bullet/indicator container */
.dot-container {
    text-align: center;
    padding: 20px;
       background: #fffffff1;
    
}

/* The dots/bullets/indicators */
.dot {
  cursor: pointer;
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

/* Add a background color to the active dot/circle */


/* Add an italic font style to all quotes */
q {font-style: italic;}

/* Add a blue color to the author */
.author {color: cornflowerblue;}

.revie{
    text-align: center;
    padding: 50px 0;
}

.revie h3{
           color: #32895E;
    font-family: 'Inter', sans-serif;
    font-size: 24px;
    font-weight: 400;
}

.revie h2{
    font-family: 'Inter', sans-serif;

    font-weight: 400;
    margin-top: 15px;
    line-height: 37px;
}


.tesstiomsecs{
        width: 530px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 -5px 8px 0px #e1e1e1;
}

.quotess{
        font-size: 45px;
    color: #32895E;
}


.test-pic-sec{
     display: flex;
    justify-content: center;
    margin-top: 29px;
}


.test-pic-im{
        margin-right: 10px;
}

.test-pic {}

.test-pic h3{
      font-size: 15px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
    margin-bottom: 0;
}

.test-pic p{
           font-family: 'Inter', sans-serif;
    color: #8d8d8d;
    font-size: 12px;
    text-align: left;
}


.footer-section{
    background-color: #000;
        padding: 50px 0;
        padding-bottom: 0;
}

.footer-logo {}

.footer-logo ul{
    text-align: center;

    margin-top: 30px;

}

.footer-logo ul li{
        display: inline-block;
    margin: 0 6px;
}

.footer-logo ul li a{
   color: #bfbfbf;
    font-size: 16px;
    border: 1px solid #fff;
    /* padding: 7px 12px; */
    border-radius: 50px;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
     font-family: 'Inter', sans-serif;
}


#img-wa{
        width: 74%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.footer-logo-text{

}

.footer-logo-text h2{
       color: #fff;
    font-size: 20px;
    font-weight: 500;
}


.footer-logo-text ul{
    padding-left: 0;
}

.footer-logo-text ul li{
    list-style: none;
        padding: 7px 0;

}

.footer-logo-text ul li a{
       color: #bfbfbf;
    text-decoration: none;
    font-weight: 400;
    font-size: 15px;
}

.button:hover{
    color: #fff;
}

.footer-end-se{
        border-top: 1px solid #7d7d7d;
    padding: 15px 0;
    margin-top: 20px;
}

.footer-end-se p{
    text-align: center;
        color: #a0a0a0;
}


#sli-img-se{
    width: 100%;
}


#g1-se{
    width: 100%;
}


.navbar-dark .navbar-toggler-icon{
    background-image: url(../images/menu.png);
}




@media screen and (min-width:992px)and (max-width:1024px){
    #weworkf {
    width: 100%;
}

.riyo-header-section::before {
  
    width: 60%;
    height: 60px;
}


.slider-section::before {
   
    width: 60%;
    height: 60px;
}


.work-schedule h2 {
   
    font-size: 30px;
}


.work-schedule {
    margin-left: 35px;
    margin-top: 50px;
    width: 100%;
}




}




  @media screen and (min-width:768px)and (max-width:991px){

    li.nav-item {
    margin-left: 0px;
        padding: 6px 0;
}

    .navbar-dark .navbar-brand {
    color: #fff;
    width: 30%;
}

.navbar-dark .navbar-nav .nav-link{
    font-size: 10px;
}

.prev, .next {
 
    margin: 0 0;
}


    .tesstiomsecs {
    width: 100%;
   
}


.riyo-header-section::before {
   
    width: 50%;
    height: 50px;
}

.work-schedule {
    margin-left: 0;
    margin-top: 0;
    width: auto;
        text-align: center;
}

.work-schedule h3 {
    font-size: 24px;
   
}


#img-wa{
        width: 74%;
   
}

li.navvnv{
    border: none;
}

#signin-button {
    background-image: linear-gradient(#32895E, #8ABE4B);
    color: #fff;
    border-radius: 6px;
    padding: 5px 26px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

.learn-how-section {
    background-color: #ededed;
    padding: 50px 0;
}

#logsedf{
    width: 100%;
}


.slider-section::before {
  
    width: 50%;
    height: 50px;
}


.work-schedule h2 {
  font-size: 26px;
}


.leanr-how-text {
    padding-top: 0;
}


.leanr-how-text h2 {
    
    text-align: center;
}

.features-section {
    position: relative;
    padding: 0 0;
}

.features h3 {
    font-size: 20px;
    text-align: center;
}

.features h2 {
    
    font-size: 20px;
   
    text-align: center;
}

.features {
    padding-top: 20px;
}

.features-section::before {
  
    background-position: right;
    
}

#img1 {
    width: 50%;
}


.oursolut-icons {
   
    width: 140px;
    height: 140px;
   
}


.oursolut-iconss {
   
    width: 140px;
    height: 140px;
   
}

.ousoltext h2 {
   
    font-size: 16px;
 
}

.price .header {
   
        height: 100px;
    font-size: 18px;
}


.price .grey span {
    font-size: 14px;
   
}

.price .grey {
    font-size: 14px;
   
}

.price li {
    padding: 10px 10px;
   
    font-size: 11px;
}

.button {
   
    padding: 9px 26px;
    font-size: 13px;
}


.teammang-secc {
    margin-left: 0;
}

.oursolut h2 {
    
    font-size: 25px;
}

.ourplans-tex h3 {
 
    font-size: 20px;
}

.ourplans-tex h2 {
 
    font-size: 23px;
}

.ourplans-section {
    padding: 30px 0;
}


.trusted-text h2 {
    
    font-size: 17px;
    line-height: 24px;
}

#g1-se {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}


.revie h2 {
 
    font-size: 20px;
}

.revie h3 {
 
    font-size: 20px;
}

.revie {
    text-align: center;
    padding: 30px 0;
}

#weworkf{
    width: 100%;
}


    .submit-btn{
        font-size: 10px;
    }


        .tesstiomsecsss {
    width: auto;
    
}

.border-sec {
    display: none;
}

    .price {
    
    margin-bottom: 20px;
}

  }


  @media screen and (min-width:0px)and (max-width:768px){

    .price {
    
    margin-bottom: 20px;
}

    .border-sec {
    display: none;
}
    .tesstiomsecsss {
    width: auto;
    
}


    .imgff{
        width: 100%;
    }


    .submit-btn{
        font-size: 10px;
    }

#weworkf{
    width: 100%;
}

    .columns {
    float: left;
    width: 100%;
        padding: 0;
}



    .revie {
    text-align: center;
    padding: 30px 0;
}

    .revie h3 {
 
    font-size: 20px;
}

    .revie h2 {
 
    font-size: 20px;
}

    #g1-se {
    width: 50%;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

    .trusted-text h2 {
    
    font-size: 17px;
    line-height: 24px;
}

    .ourplans-section {
    padding: 30px 0;
}

    .ourplans-tex h2 {
 
    font-size: 23px;
}

    .ourplans-tex h3 {
 
    font-size: 20px;
}


    .oursolut h2 {
    
    font-size: 25px;
}
    .teammang-secc {
    margin-left: 0;
}

    .features-section::before {
  
    display: none;
    
}


.oursolut-icons {
  
    width: 140px;
    height: 140px;
    
}


.oursolut-iconss {
  
    width: 140px;
    height: 140px;
    
}
.avatarr {
  
    display: inline-block;
    font-weight: 500;
    height: 45px;
    line-height: 38px;
    margin: 0 10px 0 0;
    position: relative;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
    width: 70px;
    background-color: black;
    
}

#img1 {
    width: 50%;
}

.ousoltext h2 {
    
    font-size: 20px;
   
}

.oursolut-icons {
  
    margin-top: 30px;
}

.oursolut-iconss {
  
    margin-top: 30px;
}

.leanr-how-text p {
   
    text-align: center;
}

    .features {
    padding-top: 20px;
}

    .features h2 {
    
    font-size: 20px;
   
    text-align: center;
}
    .features h3 {
    font-size: 20px;
    text-align: center;
}

    .features-section {
    position: relative;
    padding: 0 0;
}

    .leanr-how-text h2 {
    
    text-align: center;
}


    .leanr-how-text {
    padding-top: 0;
}


.work-schedule h2 {
  font-size: 26px;
}

    .slider-section::before {
  
    width: 50%;
    height: 50px;
}
    #logsedf{
    width: 50%;
}


    #signin-button {
    background-image: linear-gradient(#32895E, #8ABE4B);
    color: #fff;
    border-radius: 6px;
    padding: 5px 26px;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

    li.nav-item {
    margin-left: 10px;
    border-bottom: 1px solid #bfbfbf;
    padding: 6px 0;
}

.navbar .nav-item{
    padding: 6px 0;
}

.navbar .navbar-brand {
   
    width: 80%;
}

li.navvnv{
    border: none;
}

    #img-wa{
        width: 74%;
   
}

 

    .work-schedule h3 {
    font-size: 24px;
   
}

    .prev, .next {
 
    margin: 0 0;
}



    .tesstiomsecs {
    width: 100%;
   
}



.riyo-header-section::before {
   
    width: 50%;
    height: 50px;
}


.work-schedule {
    margin-left: 0;
    margin-top: 0;
    width: auto;
        text-align: center;
}





  }



@media (min-width: 992px){
.navbar-expand-lg .navbar-collapse {
    display: flex!important;
    flex-basis: auto;
    justify-content: right;
}
    

    
}

@media (min-width: 992px) and (max-width:1210px){
    li.nav-item{margin-left: 12px}
    .navbar .nav-link{font-size: 13px}
    
}

@media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px)  {

    table { 
        width: 100%; 
    }

    /* Force table to not be like tables anymore */
    table, thead, tbody, th, td, tr { 
        display: block; 
    }
    
    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr { 
        position: absolute;
        top: -9999px;
        left: -9999px;
    }
    
    tr { border: 1px solid #ccc; }
    
    td { 
        /* Behave  like a "row" */
        border: none;
        border-bottom: 1px solid #eee; 
        position: relative;
        padding-left: 0; 
    }

    td:before { 
        /* Now like a table header */
        position: absolute;
        /* Top/left values mimic padding */
        top: 6px;
        left: 6px;
        width: 45%; 
        padding-right: 10px; 
        white-space: nowrap;
        /* Label the data */
        content: attr(data-column);

        color: #000;
        font-weight: bold;
    }

    .featrr {
    text-align: center;
    width: auto;
    font-weight: 700;
    font-size: 13px;
}

}



@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
}

@media only screen and (max-width: 375px) {

        .price {
    
    margin-bottom: 20px;
}

.price .header {
  
    height: 160px;
}
}

@media only screen and (max-width: 320px) {

        .price {
    
    margin-bottom: 20px;
}

    .navbar .navbar-brand {
    width: 70%;
}

.price .header {
  
    height: 150px;
}


}
